<template>
	<div class="theNav" :class="[m_menu_icon === 'close' && 'theNav_active']">
		<!-- logo -->
		<slot>
			<svg class="svg_logo" aria-hidden="true">
				<use
					:xlink:href="
						m_menu_icon === 'close'
							? '#logo-skiff-black'
							: '#logo-white'
					"
				></use>
			</svg>
		</slot>

		<!-- 右侧 -->
		<div class="theNav_end">
			<!-- btn -->
			<div class="btn" @click="Booking">预约体验</div>
			<!-- m -->
			<div class="svg_m">
				<svg aria-hidden="true" @click="toggle">
					<use :xlink:href="`#${m_menu_icon}`"></use>
				</svg>
			</div>
		</div>

		<!-- 弹出 -->
		<ul class="dropdown">
			<li
				v-for="(item, index) in list"
				:key="'nav_' + index"
				class="dropdown_li"
			>
				<a
					v-if="!item.no_href"
					:href="`/${item.value}`"
					class="dropdown_li_title1 dropdown_right"
					:class="[is_active === item.value && 'active_routeName']"
				>
					{{ item.name }}
					<svg aria-hidden="true">
						<use xlink:href="#right"></use>
					</svg>
				</a>
				<span v-else class="dropdown_li_title2">
					{{ item.name }}
				</span>
				<ul class="dropdown_li_children" v-if="item.children">
					<li
						v-for="(child, c_index) in item.children"
						:key="'nav_child_' + c_index"
					>
						<a
							:href="`/${child.value}`"
							class="dropdown_right"
							:class="[
								is_active === child.value && 'active_routeName'
							]"
						>
							{{ child.name }}
							<svg aria-hidden="true">
								<use xlink:href="#right"></use>
							</svg>
						</a>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: "the-nav",
		data() {
			return {
				// 当前页面激活菜单
				is_active: "",
				// 菜单列表
				list: [
					{
						name: "首页",
						value: "index"
					},
					{
						name: "产品中心",
						value: "product",
						no_href: true,
						children: [
							{
								name: "轻EMS",
								value: "EMS"
							},
							{
								name: "储能管家",
								value: "energyguard"
							},
							{
								name: "储电医生",
								value: "doctor"
							}
						]
					},
					// {
					// 	name: "解决方案",
					// 	value: "scheme"
					// },
					{
						name: "关于我们",
						value: "about",
						no_href: true,
						children: [
							{
								name: "关于轻舟",
								value: "qingzhou"
							},
							{
								name: "新闻动态",
								value: "news"
							},
							{
								name: "加入我们",
								value: "join"
							}
						]
					}
				],
				// 展开关闭icon
				m_menu_icon: "menu"
			};
		},
		watch: {
			$route: {
				handler(to) {
					this.is_active = to.name;
					console.log(this.is_active);
				},
				immediate: true
			}
		},
		methods: {
			// 预约体验
			Booking() {
				this.$store.commit("setState", { visible: true });
			},
			// 移动端点击menu
			toggle() {
				this.m_menu_icon =
					this.m_menu_icon === "menu" ? "close" : "menu";
			}
		}
	};
</script>

<style lang="scss">
	.theNav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: px2rem(72);
		@include thinBorder(bottom, rgba(255, 255, 255, 0.15));
		padding: 0 px2rem(16);
		transition: all 0.2s linear;
		.svg_logo {
			width: px2rem(135);
			height: px2rem(40);
		}
		.svg_down {
			width: 12px;
			height: 12px;
			fill: rgba(255, 255, 255, 0.45);
			margin-left: 8px;
		}
		&_end {
			display: flex;
			align-items: center;
			.btn {
				white-space: nowrap;
				padding: px2rem(5) px2rem(24);
				background-color: rgba(255, 255, 255, 0.25);
				border-radius: px2rem(17);
				font-size: px2rem(14);
				font-family: PingFangSC, PingFangSC-Medium;
				font-weight: 500;
				text-align: center;
				color: #ffffff;
				cursor: pointer;
				&:active {
					background-color: #00c699;
					color: #ffffff;
				}
			}
			.svg_m {
				box-sizing: content-box;
				width: px2rem(24);
				height: px2rem(24);
				padding-left: px2rem(16);
				margin-left: px2rem(16);
				@include thinBorder(left, rgba(255, 255, 255, 0.25));
				svg {
					width: 100%;
					height: 100%;
					fill: #fff;
				}
			}
		}

		.dropdown {
			position: fixed;
			top: px2rem(72);
			left: -100%;
			z-index: 2;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			height: calc(100% - px2rem(72));
			background-color: #fff;
			opacity: 0;
			padding: 0 px2rem(24);
			text-align: left;
			overflow: auto;
			transition: all 0.2s linear;
			&_li {
				width: 100%;
				margin-bottom: px2rem(16);
				&_title1 {
					display: block;
					padding: px2rem(16) 0;
					@include thinBorder(bottom, #cbcdd1);
					font-size: px2rem(14);
					font-family: PingFangSC, PingFangSC-Medium;
					font-weight: 500;
					color: rgba(0, 0, 0, 0.65);
				}
				&_title2 {
					display: block;
					padding-bottom: px2rem(8);
					@include thinBorder(bottom, #cbcdd1);
					font-size: px2rem(12);
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					color: rgba(0, 0, 0, 0.45);
				}
				&_children {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					li {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						padding: px2rem(16) 0 px2rem(16) px2rem(16);
						@include thinBorder(bottom, #cbcdd1);
						a {
							font-size: px2rem(14);
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							color: rgba(0, 0, 0, 0.65);
						}
					}
				}
			}
			&_right {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				svg {
					width: px2rem(16);
					height: px2rem(16);
					fill: rgba(0, 0, 0, 0.45);
				}
			}
		}
	}
	.active_routeName {
		color: #00c699 !important;
	}

	.theNav_active {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 3;
		width: 100%;
		background-color: #fff;
		@include thinBorder(bottom, rgba(0, 0, 0, 0.15));
		.dropdown {
			left: 0;
			opacity: 1;
		}
		.theNav_end {
			.btn {
				background-color: #00c699;
				color: #ffffff;
			}
			.svg_m {
				@include thinBorder(left, rgba(0, 0, 0, 0.15));
				svg {
					fill: #000000;
				}
			}
		}
	}
</style>
