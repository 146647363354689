import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: { path: "/index" },
    },
    {
        path: "/index",
        name: "index",
        meta: {
            title: "轻舟能科：储能EMS 储能云平台 技术专家",
        },
        component: () => import("@/views/home/index"),
    },
    {
        path: "/EMS",
        name: "EMS",
        meta: {
            title: "轻ems：工商业储能ems 能量管理系统",
        },
        component: () => import("@/views/EMS/index"),
    },
    {
        path: "/energyguard",
        name: "energyguard",
        meta: {
            title: "储能管家 - 轻舟能科官网",
        },
        component: () => import("@/views/energyguard/index"),
    },
    {
        path: "/doctor",
        name: "doctor",
        meta: {
            title: "储电医生 - 轻舟能科官网",
        },
        component: () => import("@/views/doctor/index"),
    },
    {
        path: "/qingzhou",
        name: "qingzhou",
        meta: {
            title: "关于我们 - 轻舟能科官网",
        },
        component: () => import("@/views/qingzhou/index"),
    },
    {
        path: "/news",
        name: "news",
        meta: {
            title: "新闻动态 - 轻舟能科官网",
        },
        component: () => import("@/views/news/index"),
    },
    {
        path: "/news/detail",
        name: "newsDetail",
        meta: {
            title: "新闻详情 - 轻舟能科官网",
        },
        component: () => import("@/views/news/detail"),
    },
    {
        path: "/join",
        name: "join",
        meta: {
            title: "加入我们 - 轻舟能科官网",
        },
        component: () => import("@/views/qingzhou/join"),
    },
    { path: "*", component: () => import("@/views/notFound/index") },
];

function fetchUVPV() {
    Vue.prototype.$getJSON("/api/statistic/call/me").then(() => {
        console.log("FETCH UVPV");
    });
}

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.afterEach((to) => {
    document.title = to.meta.title || "轻舟能科官网";
    fetchUVPV();
    window.scrollTo(0, 0);
});

export default router;
