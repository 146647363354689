import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import wholeInstall from "./components/index";
wholeInstall(Vue);

Vue.config.productionTip = false;

Vue.prototype.$message = {
	info(msg, sec, func) {
		let div = document.createElement("div");
		div.setAttribute("class", "_all_message");
		div.innerHTML = msg;
		document.body.appendChild(div);
		setTimeout(function () {
			div.remove();
			func && func();
		}, sec || 1000);
	}
};

Vue.prototype.$getJSON = async function getJSON(url, opt) {
	try {
		opt["_loading"] && store.commit("setState", { loading: true });
		let response = await fetch(url, { ...opt });
		console.log(response);
		if (response.ok) {
			store.commit("setState", { loading: false });
			if (response.status === 200) {
				return await response.json();
			} else {
				throw new Error(response.status);
			}
		} else {
			throw new Error(response.status);
		}
	} catch (error) {
		store.commit("setState", { loading: false });
		this.$message.info("请求失败", error);
	}
};

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
