<template>
	<div class="theLoading">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			width="24px"
			height="30px"
			viewBox="0 0 24 30"
			style="enable-background: new 0 0 50 50"
			xml:space="preserve"
		>
			<rect x="0" y="9.22656" width="4" height="12.5469" fill="#00C699">
				<animate
					attributeName="height"
					attributeType="XML"
					values="5;21;5"
					begin="0s"
					dur="0.6s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="y"
					attributeType="XML"
					values="13; 5; 13"
					begin="0s"
					dur="0.6s"
					repeatCount="indefinite"
				></animate>
			</rect>
			<rect x="10" y="5.22656" width="4" height="20.5469" fill="#00C699">
				<animate
					attributeName="height"
					attributeType="XML"
					values="5;21;5"
					begin="0.15s"
					dur="0.6s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="y"
					attributeType="XML"
					values="13; 5; 13"
					begin="0.15s"
					dur="0.6s"
					repeatCount="indefinite"
				></animate>
			</rect>
			<rect x="20" y="8.77344" width="4" height="13.4531" fill="#00C699">
				<animate
					attributeName="height"
					attributeType="XML"
					values="5;21;5"
					begin="0.3s"
					dur="0.6s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="y"
					attributeType="XML"
					values="13; 5; 13"
					begin="0.3s"
					dur="0.6s"
					repeatCount="indefinite"
				></animate>
			</rect>
		</svg>
	</div>
</template>

<script>
	export default {
		name: "the-loading"
	};
</script>

<style lang="scss">
	.theLoading {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		width: 100vw !important;
		min-width: auto !important;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.35);
		svg {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
</style>
