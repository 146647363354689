<template>
    <div class="theFooter">
        <!-- footer1 -->
        <div class="theFooter_f1">
            <h1>开启储能行业数智化时代</h1>
            <div class="yuyue_btn" @click="booking">预约体验</div>
        </div>
        <!-- footer2 -->
        <div class="theFooter_f2">
            <div class="f2_logo">
                <svg aria-hidden="true">
                    <use xlink:href="#logo-white"></use>
                </svg>
            </div>
            <div class="f2_info">
                <div class="f2_info_title" @click="toggle(0)">
                    <span>产品服务</span>
                    <svg aria-hidden="true">
                        <use xlink:href="#right"></use>
                    </svg>
                </div>
                <ul class="f2_info_dropdown">
                    <li><a href="/EMS">轻EMS</a></li>
                    <li><a href="/energyguard">储能管家</a></li>
                    <li><a href="/doctor">储电医生</a></li>
                </ul>
            </div>
            <div class="f2_info">
                <div class="f2_info_title" @click="toggle(1)">
                    <span>关于我们</span>
                    <svg aria-hidden="true">
                        <use xlink:href="#right"></use>
                    </svg>
                </div>
                <ul class="f2_info_dropdown">
                    <li><a href="/qingzhou">关于轻舟</a></li>
                    <li><a href="/news">新闻动态</a></li>
                    <li><a href="/join">加入我们</a></li>
                </ul>
            </div>
            <div class="f2_info f2_contact">
                <div class="f2_info_title">
                    <span>联系我们</span>
                </div>
                <ul>
                    <li>
                        <svg aria-hidden="true">
                            <use xlink:href="#phone-fill"></use>
                        </svg>
                        18767138236 闻经理
                    </li>
                    <li>
                        <svg aria-hidden="true">
                            <use xlink:href="#mail-fill"></use>
                        </svg>
                        info@skiffenergy.com
                    </li>
                    <li>
                        <svg aria-hidden="true">
                            <use xlink:href="#location-fill"></use>
                        </svg>
                        杭州市余杭区仓前街道鼎创财富中心B2 8层 801
                    </li>
                </ul>
            </div>
            <div class="f2_qrcode">
                <div class="qrcode_item">
                    <img
                        src="../assets/imgs/img-qrcode-a.png"
                        alt="关注我们"
                        srcset=""
                    />
                    <div class="qrcode_item_label">关注我们</div>
                </div>
                <div class="qrcode_item">
                    <img
                        src="../assets/imgs/shangwuwx.png"
                        alt="商务微信"
                        srcset=""
                    />
                    <div class="qrcode_item_label">商务微信</div>
                </div>
            </div>
            <div class="f2_copyright">
                Copyright © 2021 杭州轻舟科技有限公司
                <br />
                浙ICP备16033476号-1
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "the-footer",
    data() {
        return {};
    },
    methods: {
        booking() {
            this.$store.commit("setState", { visible: true });
        },
        toggle(index) {
            const queryTitle = document.querySelectorAll(".f2_info");
            if (queryTitle[index].classList.length === 1) {
                queryTitle[index].classList.add("f2_active");
            } else {
                queryTitle[index].classList.remove("f2_active");
            }
        },
    },
};
</script>

<style lang="scss">
.theFooter {
    &_f1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: px2rem(348);
        background-image: url("../assets/mobile/img-m-bottom-a-blue.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        h1 {
            width: px2rem(180);
            font-size: px2rem(30);
            font-family: AlibabaPuHuiTiM;
            color: #ffffff;
            text-align: center;
        }
        .yuyue_btn {
            width: px2rem(144);
            height: px2rem(48);
            line-height: px2rem(48);
            margin-top: px2rem(32);
            background-color: rgba(255, 255, 255, 0.25);
            @include thinBorder(
                (top, left, right, bottom),
                rgba(255, 255, 255, 0.15),
                px2rem(2)
            );
            border-radius: px2rem(2);
            text-align: center;
            font-size: px2rem(16);
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;
            &:active {
                background-color: #00c699;
                color: #ffffff;
            }
        }
    }
    &_f2 {
        background-image: url("../assets/mobile/img-m-ph-blue-bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-attachment: fixed;
        .f2_logo {
            display: flex;
            align-items: center;
            height: px2rem(104);
            padding: 0 px2rem(24);
            @include thinBorder(bottom, rgba(255, 255, 255, 0.15));
            svg {
                width: px2rem(135);
                height: px2rem(40);
            }
        }
        .f2_info {
            &_title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: px2rem(55);
                padding: 0 px2rem(24);
                @include thinBorder(bottom, rgba(255, 255, 255, 0.15));
                > span {
                    font-size: px2rem(14);
                    font-family: AlibabaPuHuiTiM;
                    text-align: left;
                    color: #ffffff;
                }
                svg {
                    width: px2rem(16);
                    height: px2rem(16);
                    fill: rgba(255, 255, 255, 0.45);
                    transform: rotate(90deg);
                    transition: transform 0.2s ease-in-out;
                }
            }
            &_dropdown {
                height: 0;
                padding: 0 px2rem(24);
                background-color: #000a35;
                transition: height 0.2s ease-in-out;
                overflow: hidden;
                li {
                    height: px2rem(55);
                    line-height: px2rem(55);
                    @include thinBorder(bottom, rgba(255, 255, 255, 0.15));
                    a {
                        display: block;
                        font-size: px2rem(14);
                        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: rgba(255, 255, 255, 0.45);
                    }
                }
            }
        }
        .f2_active {
            .f2_info_title {
                svg {
                    transform: rotate(-90deg);
                }
            }
            .f2_info_dropdown {
                height: px2rem(164);
            }
        }
        .f2_contact {
            padding: 0 px2rem(24);
            .f2_info_title {
                padding: 0;
                &::after {
                    display: none;
                }
            }
            ul {
                margin-top: px2rem(8);
                margin-bottom: px2rem(40);
                li {
                    display: flex;
                    align-items: center;
                    font-size: px2rem(16);
                    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: rgba(255, 255, 255, 0.45);
                    margin-bottom: px2rem(16);
                    svg {
                        width: px2rem(18);
                        height: px2rem(18);
                        fill: rgba(255, 255, 255, 0.65);
                        margin-right: px2rem(8);
                    }
                }
            }
        }
        .f2_qrcode {
            display: flex;
            justify-content: space-around;
            margin-bottom: px2rem(40);
            .qrcode_item {
                display: flex;
                flex-direction: column;
                img {
                    width: px2rem(140);
                    height: px2rem(140);
                    margin-bottom: px2rem(8);
                }
                &_label {
                    font-size: px2rem(14);
                    font-family: NotoSansSC, NotoSansSC-Regular;
                    font-weight: 400;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.45);
                }
            }
        }
        .f2_copyright {
            height: px2rem(57);
            line-height: 2;
            @include thinBorder(top, rgba(255, 255, 255, 0.15));
            text-align: center;
            font-size: px2rem(12);
            font-family: AlibabaPuHuiTiR;
            text-align: center;
            color: rgba(255, 255, 255, 0.45);
        }
    }
}
</style>
