<template>
	<div class="theModal">
		<div class="theModal_container">
			<div class="theModal_container_header">
				<span>预约体验</span>
				<svg class="close" aria-hidden="true" @click="cancel">
					<use xlink:href="#close"></use>
				</svg>
			</div>
			<div class="theModal_container_con">
				<div class="tcc_r1">
					<span>感兴趣的产品(多选)</span>
					<div class="tcc_r1_r2">
						<div
							class="checkbox_item"
							v-for="(item, index) in product"
							:key="'pro' + index"
						>
							<input
								:class="[ios && 'ios_checkbox']"
								type="checkbox"
								:id="item.name"
								v-model="item.checked"
							/>
							<label :for="item.name">{{ item.name }}</label>
						</div>
					</div>
				</div>
				<div
					class="tcc_row"
					:class="[index >= 3 && 'tcc_textarea']"
					v-for="(item, index) in make"
					:key="'make' + index"
				>
					<div class="tcc_row_label">{{ item.name }}</div>
					<div class="tcc_row_input">
						<input
							type="text"
							v-model="item.value"
							placeholder="请输入"
							v-if="index < 3"
						/>
						<textarea
							v-else
							rows="2"
							:maxlength="limit.max"
							placeholder="请输入"
							v-model="item.value"
						></textarea>
						<div class="limit" v-show="index == 3">
							{{ item.value.length }}/{{ limit.max }}
						</div>
					</div>
				</div>
			</div>
			<div class="theModal_container_footer">
				<div class="btn" @click="cancel">关闭</div>
				<div class="btn btn_2" @click="submit">提交预约</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "the-modal",
		data() {
			return {
				product: [
					{
						name: "轻EMS",
						checked: false
					},
					{
						name: "储能管家",
						checked: false
					},
					{
						name: "储电医生",
						checked: false
					}
				],
				make: [
					{
						name: "姓名",
						value: ""
					},
					{
						name: "手机",
						value: ""
					},
					{
						name: "公司",
						value: ""
					},
					{
						name: "留言",
						value: ""
					}
				],
				limit: {
					max: 200
				},
				ios: false
			};
		},
		created() {
			this.checkIOS();
		},
		methods: {
			checkIOS() {
				var u = navigator.userAgent;
				this.ios = !u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			},
			cancel() {
				this.product.map(item => (item.checked = false));
				this.make.map(item => (item.value = ""));
				this.$store.commit("setState", { visible: false });
			},
			submit() {
				let obj = {
					companyName: "",
					content: "",
					phone: "",
					products: "",
					username: ""
				};
				let flag = this.product.some(item => item.checked);
				if (!flag) {
					this.$message.info("请选择产品");
					return;
				} else {
					for (let item of this.product) {
						if (item.checked) obj.products += item.name;
					}
				}
				if (this.make.some(item => !item.value)) {
					this.$message.info("请填写完整信息");
					return;
				} else {
					obj.username = this.make[0].value;
					obj.phone = this.make[1].value;
					obj.companyName = this.make[2].value;
					obj.content = this.make[3].value;
				}
				this.$getJSON("/api/base/mail", {
					_loading: true,
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8"
					},
					body: JSON.stringify(obj)
				}).then(() => {
					this.$message.info("预约成功");
					this.cancel();
				});
			}
		}
	};
</script>

<style lang="scss">
	.theModal {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.65);
		display: flex;
		justify-content: center;
		align-items: flex-end;
		&_container {
			width: 100%;
			height: px2rem(501);
			background-color: #ffffff;
			border-top-left-radius: px2rem(20);
			border-top-right-radius: px2rem(20);
			&_header {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				height: px2rem(47);
				margin: 0 px2rem(24);
				@include thinBorder(bottom, #ebedf0);
				> span {
					position: absolute;
					left: 50%;
					top: 50%;
					z-index: 2;
					transform: translate(-50%, -50%);
					font-size: px2rem(16);
					font-family: PingFangSC, PingFangSC-Medium;
					font-weight: 500;
					color: rgba(0, 0, 0, 0.85);
				}
				.close {
					width: px2rem(14);
					height: px2rem(14);
					fill: #c8c9cc;
				}
			}
			&_con {
				box-sizing: border-box;
				.tcc_r1 {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding: px2rem(24);
					@include thinBorder(bottom, #ebedf0);
					> span,
					label {
						font-size: px2rem(14);
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						text-align: left;
						color: rgba(0, 0, 0, 0.65);
					}
					&_r2 {
						display: flex;
						align-items: center;
						margin-top: px2rem(16);
						.checkbox_item {
							display: flex;
							align-items: center;
							margin-right: px2rem(32);
							&:last-child {
								margin-right: 0;
							}
							input[type="checkbox"] {
								position: relative;
								width: px2rem(16);
								height: px2rem(16);
								margin-right: px2rem(8);
								cursor: pointer;
							}
							input[type="checkbox"].ios_checkbox::after {
								position: absolute;
								top: 0;
								left: 0;
								z-index: 2;
								display: block;
								content: "";
								width: px2rem(16);
								height: px2rem(16);
								text-align: center;
								border-radius: px2rem(2);
							}
							input[type="checkbox"].ios_checkbox:checked::after {
								content: "\2713";
								color: #ffffff;
								font-weight: bold;
								background-color: #00c699;
								line-height: px2rem(16);
								text-align: center;
							}
						}
					}
				}
				.tcc_row {
					display: flex;
					align-items: center;
					padding-top: px2rem(12);
					padding-bottom: px2rem(12);
					@include thinBorder(bottom, #ebedf0);
					margin: 0 px2rem(16);
					&:last-child {
						margin-bottom: 0;
					}
					&_label {
						font-size: px2rem(14);
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						text-align: left;
						color: rgba(0, 0, 0, 0.65);
					}
					&_input {
						input[type="text"] {
							width: 100%;
							height: 100%;
							box-sizing: border-box;
							outline: none;
							padding: 0 px2rem(16);
							font-size: px2rem(14);
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							color: rgba(0, 0, 0, 0.65);
							border-color: transparent;
						}
						textarea {
							width: 100% !important;
							height: 100%;
							outline: none;
							padding: px2rem(8) 0;
							border: 1px solid rgba(0, 0, 0, 0.15);
							box-sizing: border-box;
							resize: none;
							font-size: px2rem(14);
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							color: rgba(0, 0, 0, 0.65);
							border-color: transparent;
						}
						textarea::placeholder,
						input[type="text"]::placeholder {
							font-size: px2rem(14);
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							text-align: left;
							color: rgba(0, 0, 0, 0.25);
						}
					}
				}
				.tcc_textarea {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					margin: 0;
					padding: 0;
					.tcc_row_label {
						width: 100%;
						padding: px2rem(8) px2rem(16);
					}
					.tcc_row_input {
						position: relative;
						width: 100%;
						height: px2rem(88);
						padding: 0 px2rem(16);
						padding-bottom: px2rem(24);
						@include thinBorder(top, #ebedf0);
						textarea {
							display: block;
						}
						.limit {
							position: absolute;
							right: px2rem(16);
							bottom: px2rem(8);
							font-size: px2rem(12);
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							text-align: right;
							color: #999999;
						}
					}
				}
			}
			&_footer {
				display: flex;
				align-items: center;
				justify-content: center;
				height: px2rem(70);
				.btn {
					width: px2rem(168);
					height: px2rem(40);
					line-height: px2rem(40);
					background-color: #ffffff;
					@include thinBorder((top, right, bottom, left), #ebedf0);
					font-size: px2rem(14);
					font-family: PingFangSC, PingFangSC-Medium;
					font-weight: 500;
					text-align: center;
					color: rgba(0, 0, 0, 0.65);
					cursor: pointer;
				}
				.btn_2 {
					color: #ffffff;
					background-color: #00c699;
					border: none;
					margin-left: px2rem(8);
				}
			}
		}
	}
</style>
