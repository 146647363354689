import theNav from "./the-nav";
import theFooter from "./the-footer";
import theModal from "./the-modal";
import theLoading from "./the-loading";
export default function wholeInstall(Vue) {
	Vue.component(theNav.name, theNav);
	Vue.component(theFooter.name, theFooter);
	Vue.component(theModal.name, theModal);
	Vue.component(theLoading.name, theLoading);
}
